import '../App.css';
import backgroundumage from '../assest/backgroundim.jpg';
import issa from '../assest/issa.png';
import seclogo from '../assest/logob2.png';
import { Helmet } from "react-helmet";

export default function Example() {
  return (
    <>
    <Helmet>
        <title>Issa Al-Tabbal - Official Website</title>
        <meta name="description" content="Welcome to Issa Al-Tabbal's official website. Learn more about him here!" />
        <meta name="keywords" content="Issa Al-Tabbal, issaaltabbal, عيسى الطبال" />
        <meta name="author" content="Issa Omar Al-Tabbal" />
      </Helmet>
      <div class="flex flex-col md:flex-row justify-between relative mx-auto max-w-7xl px-6 lg:px-20 py-20">

        <div className='w-full md:w-[300px] text-black pb-5'>
          <h1 className='text-3xl font-semibold'>About me</h1>
        </div>

        <div className='w-full text-black'>
          <h1 className='text-lg font-light text-left opacity-90'>Hello, my name is Issa Altabbal, a branding and visual identity specialist based in Tripoli, Libya, born in 1998. After evolving from an artist to a graphic designer in 2017, I have dedicated myself to crafting distinctive visual identities that elevate businesses in the marketplace. My goal is to enhance awareness of branding and design's significance through educational content, simplifying these concepts for business owners and designers. I also aim to highlight Libyan identity, promoting national heritage and societal progress.</h1>
        </div>

      </div>


      <div class="flex flex-row justify-between relative mx-auto max-w-7xl px-6 lg:px-20 pb-20">

        <div className='w-[300px] text-black'>
          <h1 className='text-3xl font-semibold'>coming soon...</h1>
        </div>
      </div>
    </>
  )
}
