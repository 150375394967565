import '../App.css';
import backgroundumage from '../assest/backgroundim.svg';
import issa from '../assest/issa.png';
import seclogo from '../assest/logob2.png';
import { Helmet } from "react-helmet";

export default function Example() {
  return (
    <div className="relative isolate overflow-hidden py-[150px] pt-36 sm:py-40 md:py-30">
    <Helmet>
        <title>Issa Al-Tabbal - Official Website</title>
        <meta name="description" content="Welcome to Issa Al-Tabbal's official website. Learn more about him here!" />
        <meta name="keywords" content="Issa Al-Tabbal, issaaltabbal, عيسى الطبال" />
        <meta name="author" content="Issa Omar Al-Tabbal" />
      </Helmet>
      <img
        src={backgroundumage}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-[-100px] sm:object-left" />
      <img  
        src={issa}
        alt=""
        className="responsive-image max-w-none absolute bottom-0 right-[calc(50%-330px)] md:right-[calc(50%-250px)] lg:right-[calc(50%-350px)] -z-6 h-auto w-[32rem] sm:w-[35rem] md:w-[35rem] lg:w-[40rem]"
      />
      <img
        src={seclogo}
        alt=""
        className="absolute bottom-[calc(50%-90px)] left-[20px] lg:left-auto md:right-[calc(50%+120px)] sm:bottom-[calc(50%-80px)] sm:right-[calc(50%+220px)] md:bottom-[calc(50%-180px)] md:right-[calc(50%+250px)] lg:bottom-[calc(50%+50px)] lg:right-[calc(50%+150px)] -z-5 h-auto w-[4rem] md:w-[6rem]"
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-20">
        <div class="flex flex-row justify-between relative">

          <div className='pb-60 md:pb-40 text-white'>
            <h1 className='text-4xl sm:text-5xl md:text-5xl lg:text-6xl w-[40px] leading-[45px] sm:leading-[55px] md:leading-[60px] lg:leading-[70px]'><span className='font-semibold'>Designing Brands,</span></h1>

            <h1 className='text-4xl sm:text-5xl md:text-5xl lg:text-6xl w-[40px] leading-[45px] sm:leading-[55px] md:leading-[60px] lg:leading-[70px]'><span className='span2'>Inspiring Change.</span></h1>
          </div>



          <div className=''>
            <div className='w-full md:w-auto absolute bottom-0 right-0'>
              <h1 className='text-xl md:text-3xl leading-[20px]'><span className='font-bold'>Branding</span></h1>
              <h1 className='text-xl md:text-3xl'><span className='span2'>Designer.</span></h1>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}
