import '../App.css';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaBehance } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Helmet } from "react-helmet";

export default function Example() {
    return (
        <div className=' bg-[#000000] relative'>
    <Helmet>
        <title>Issa Al-Tabbal - Official Website</title>
        <meta name="description" content="Welcome to Issa Al-Tabbal's official website. Learn more about him here!" />
        <meta name="keywords" content="Issa Al-Tabbal, issaaltabbal, عيسى الطبال" />
        <meta name="author" content="Issa Omar Al-Tabbal" />
      </Helmet>
            <div className="flex flex-col md:flex-row items-center justify-between px-6 lg:px-20 py-8 mx-auto max-w-7xl">

                <div className='md:hidden flex flex-row gap-8 w-fit mb-5'>
                    <a href='https://www.instagram.com/issa.altabbal?igsh=a2U1b3E5dmVzb2Vn'><FaInstagram className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    <a href='https://www.behance.net/issaaltabbal?fbclid=IwZXh0bgNhZW0CMTEAAR0hpSpl9tO6Yk5JA14oBtfWpoN_WYpg7KTtwUYp6lNUB9AQH3vCTsaAQ74_aem_YP9lnt4qjVoA7exofRtV-g'><FaBehance className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    <a href='https://www.facebook.com/Issa.altabbal?mibextid=ZbWKwL'><FaFacebookF className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    <a href='https://www.linkedin.com/in/issa-altabbal-a17b751a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedinIn className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    
                </div>

                <h2 className='text-white'>© 2025  All rights reserved.</h2>



                <div className='hidden md:flex flex-row gap-8'>
                    <a href='https://www.instagram.com/issa.altabbal?igsh=a2U1b3E5dmVzb2Vn'><FaInstagram className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    <a href='https://www.behance.net/issaaltabbal?fbclid=IwZXh0bgNhZW0CMTEAAR0hpSpl9tO6Yk5JA14oBtfWpoN_WYpg7KTtwUYp6lNUB9AQH3vCTsaAQ74_aem_YP9lnt4qjVoA7exofRtV-g'><FaBehance className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    <a href='https://www.facebook.com/Issa.altabbal?mibextid=ZbWKwL'><FaFacebookF className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                    <a href='https://www.linkedin.com/in/issa-altabbal-a17b751a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedinIn className='text-3xl text-white opacity-80 hover:text-[#ffff00]' /></a>
                </div>

            </div>
        </div>
    )
}
